import PageFoot from "@/components/PageFoot";
import PageContainer from "@/components/PageContainer";
import getFootLink from "@/utils/getFootLink";
import { FC, useCallback } from "react";
interface MainLayoutProps {
  children?: React.ReactNode | ((mainInfo: { onLogin?: PageContainerInfo['onLogin'] }) => React.ReactNode);
  navData: Nav[];
  websiteInfo: SiteInfo;
}
const MainLayout: FC<MainLayoutProps> = ({
  children,
  navData,
  websiteInfo,
}) => {


  const { activityLink, newsLink } = getFootLink(navData);

  const renderChildren = useCallback(({ onLogin }: PageContainerInfo) => {
    if (typeof children === "function") {
      return children({ onLogin });
    }
    return children;
  }
    , [children]);

  // 渲染children 如果是函数则传入参数
  return (
    <div className="main-layout">
      <PageContainer
        navData={navData}
        footer={
          <PageFoot
            activityLink={activityLink}
            newsLink={newsLink}
            websiteInfo={websiteInfo}
          />
        }
      >
        {
          (containerInfo) => (
            // 传入containerInfo 用于获取
            <main role="main" className="main">
              {renderChildren(containerInfo)}
            </main>
          )
        }
      </PageContainer>
    </div>
  );
}

export default MainLayout;