export enum MemberType {
  "member_1" = "医院",
  "member_2" = "企业",
  "member_3" = "政府",
  "member_4" = "出版社",
  "member_5" = "基金会",
  "member_6" = "协会",
  "member_7" = "研究所",
  "member_8" = "患者组织",
  "member_9" = "高等院校",
}

// 用户类型
export const USER_TYPE: Option[] = [
  { label: '医生', value: 1 },
  { label: '患者', value: 7 },
  { label: '护士', value: 2 },
  { label: '药师', value: 3 },
  { label: '普通用户', value: 5 },
  { label: '医学生', value: 6 },
  { label: '医学院校教师', value: 8 },
];

// Code错误码
export enum CODE_STATUS {
  // token过期
  TOKEN_EXPIRED = '14000',
  // 二维码已失效
  QRCODE_EXPIRED = '11001',
}
// Code错误文本
export enum CODE_TEXT {
  // token过期
  TOKEN_EXPIRED = '登录已失效，请重新登录！',
  // 二维码已失效
  QRCODE_EXPIRED = '登录二维码过期！',
}
